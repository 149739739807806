import React, { useEffect, useState } from "react";
import "./App.css";
import Search from "./Asset/search.png";
import Help from "./Asset/help.png";
// import TnLogo from "./Asset/tamilnadu-govt-logo.png";
import TnLogo from "./Asset/MMD.png"
import faqlogo from "./Asset/FAQ(3).jpg";
import Carousel from "react-bootstrap/Carousel";
import firstSlideimage from "./Asset/milky_mist_home.jpg"
import secondSlide from "./Asset/milky_mist_home1.jpg";
import thirdslideimage from "./Asset/milky mist hom (2).jpg"
import lastslideimg from "./Asset/mily mist home (3).jpg"
import Menu from "./Asset/menu.svg";
import Cross from "./Asset/cross.svg";

const Chatbot = () => {
  const [isWebSocketOpen, setIsWebSocketOpen] = useState(false);
  const [openSecondScript, setpenSecondScript] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const loadRasaWebchatScript = () => {
      const script = document.createElement("script");
      script.src = "https://cdn.jsdelivr.net/npm/rasa-webchat/lib/index.js";
      script.async = true;
      script.onload = () => {
        try {
          let shouldLoadLiveAgent = false;

          // Initialize Rasa Webchat here
          window.WebChat.default(
            {
              socketUrl: "https://milkymist.emeetify.com/",
              // socketUrl: "https://d4c3-183-82-250-82.ngrok-free.app/",
              title: "Milky Mist Care ❤️",
              socketPath: "/rasa/socket.io",

              subtitle: "Welcome to Milky Mist! 🥛✨",
              initPayload: "hi",
              // initPayload:JSON.stringify({
              //   text: "hi",
              //   metadata: {
              //     latitude: "12.0168",
              //     longitude: "77.9558",
              //   },
              // }),
              params: {
                storage: "session", // Change to 'local' or 'session' as needed,
              },
              styleOptions: {
                // Define your custom styles here
                bubbleBackground: "#2196F3",
                bubbleTextColor: "white",
                userBubbleBackground: "#4caf50",
                userBubbleTextColor: "white",
              },
              onSocketEvent: {
                bot_uttered: (res) => {
                  console.log("ressss---->", res.text);
                  if (
                    res.text === "Live agent is connecting..." ||
                    res.text === "நேரடி முகவர் இணைக்கிறார்..."
                  ) {
                    check();
                    // shouldLoadLiveAgent = true;
                  }
                },
              },
            },
            null
          );

          // Check your condition here

          const check = () => {
            console.log("called");
            const liveAgentScript = document.createElement("script");
            liveAgentScript.type = "text/javascript";
            liveAgentScript.innerHTML = `window.__lc = window.__lc || {};
            window.__lc.license = 16648533;
            (function (n, t, c) {
              function i(n) {
                return e._h ? e._h.apply(null, n) : e._q.push(n);
              }
              var e = {
                _q: [],
                _h: null,
                _v: "2.0",
                on: function () {
                  i(["on", c.call(arguments)]);
                },
                once: function () {
                  i(["once", c.call(arguments)]);
                },
                off: function () {
                  i(["off", c.call(arguments)]);
                },
                get: function () {
                  if (!e._h)
                    throw new Error(
                      "[LiveChatWidget] You can't use getters before load."
                    );
                  return i(["get", c.call(arguments)]);
                },
                call: function () {
                  i(["call", c.call(arguments)]);
                },
                init: function () {
                  var n = t.createElement("script");
                  (n.async = !0),
                    (n.type = "text/javascript"),
                    (n.src = "https://cdn.livechatinc.com/tracking.js"),
                    t.head.appendChild(n);
                },
              };
              !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
            })(window, document, [].slice);
            `;
            document.head.appendChild(liveAgentScript);
          };
        } catch (error) {
          console.error("Error initializing WebChat:", error);
        }
      };
      script.onerror = (error) => {
        console.error("Error loading Rasa Webchat script:", error);
      };
      document.head.appendChild(script);
    };

    loadRasaWebchatScript();

    return () => {
      const scriptElement = document.querySelector(
        'script[src="https://cdn.jsdelivr.net/npm/rasa-webchat/lib/index.js"]'
      );
      if (scriptElement) {
        scriptElement.remove();
      }
      window.location.reload();
    };
  }, []);

  const handleCLick = () => {
    setShow(!show);
  };

  return (
    <div className="body">
      <div id="webchat" />
      <div>
        <header className="header">
          <div>
            <img id="tn-logo" className="header-logo" src={TnLogo} />
          </div>
          <div className="header-content">
            <ul className="list-items">
              <li className="list-inside">Overview</li>
              <li className="list-inside">Places to Visit</li>
              <li className="list-inside">Photos</li>
              <li className="list-inside">Foods</li>
              <li className="list-inside">About</li>
              <li className="list-inside">Contact</li>
            </ul>
          </div>
          <div className="header-leftpart">
            <div className="para">
              <img className="faqlogo" src={faqlogo} />
            </div>
            <div className="animation-class1">
              <div className="animation-class2">
                <input
                  type="text"
                  placeholder="Search"
                  className="search-field"
                />
                <img className="searchicon1" src={Search} />
              </div>
            </div>
            <div onClick={handleCLick} className="menu">
              {show ? (
                <>
                  <img
                    src={Cross}
                    style={{
                      position: "relative",
                      width: "30px",
                      transition: "1.3s ease",
                    }}
                  />
                  <div
                    className="list-contain"
                  >
                    <ul className="list-items">
                      <li className="list-inside">Overview</li>
                      <li className="list-inside">Places to Visit</li>
                      <li className="list-inside">Photos</li>
                      <li className="list-inside">Foods</li>
                      <li className="list-inside">About</li>
                      <li className="list-inside">Contact</li>
                      <li className="list-inside">FAQ</li>
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={Menu}
                    style={{ position: "relative", transition: "1.3s ease" }}
                  />
                </>
              )}
            </div>
          </div>
        </header>
      </div>
      <div className="main-div">
        <Carousel>
          <Carousel.Item interval={1000}>
            <img src={firstSlideimage} className="d-block  first-slide" />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={1000}>
            <img src={secondSlide} className="d-block slide" />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={thirdslideimage} className="d-block slide" />
            <Carousel.Caption interval={1000}></Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img src={lastslideimg} className="d-block  slide" />
            <Carousel.Caption interval={1000}></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>{" "}
    </div>
  );
};

export default Chatbot;
